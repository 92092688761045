<template>
    <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
        <div>
            <div v-if="appData">
                <instructions :instructions-text="instructions" />

                <div class="mt-10">
                    <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
                        <h4 class="font-weight-bold text-dark">
                            <i class="fa-solid fa-wave-square fa-md text-primary"></i>
                            <span class="ml-2" style="vertical-align: center">Transaction Type</span>
                        </h4>
                        <ValidationProvider rules="required" name="TransactionType" v-slot="{ errors }">
                            <v-radio-group v-model="appData.transaction.K_Type" @change="transactionTypeChanged">
                                <!-- TODO: future implementation: true new business -->
                                <!-- <v-radio label="A brand new policy" value="New Business" class="switch-radio"
                                :disabled="isReadOnly"></v-radio> -->
                                <v-radio label="Take-Up (existing policy)" value="Take-Up" class="switch-radio"
                                    :disabled="isReadOnly"></v-radio>

                            </v-radio-group>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-card>
                </div>


                <policy-details :app-data="appData" :ref-data="refData" :is-read-only="isReadOnly" v-on="$listeners" />

                <building-details :app-data="appData" :ref-data="refData" :is-read-only="isReadOnly" v-on="$listeners" />

                <div class="d-flex justify-content-end mt-5">
                    <b-button class="btn btn-primary" :disabled="isReadOnly || isSaving" @click="saveChanges">
                        <b-spinner small v-if="isSaving" class="mr-1"></b-spinner>
                        <i class="fas fa-floppy-disk"></i> Save Changes
                    </b-button>
                </div>

                <div class="mt-10">
                    <h4>How do you wish to enter/obtain the building details?</h4>

                    <ValidationProvider name="declarationRequired" v-slot="{ errors }">
                        <v-radio-group v-model="appData.declarationRequired" @change="handleDeclarationChange">
                            <v-radio label="I want to send a Declaration Form to the Strata Manager" value="yes"
                                class="switch-radio" :disabled="isReadOnly"></v-radio>
                            <v-radio label="I will enter these manually" value="no" class="switch-radio"
                                :disabled="true"></v-radio>
                        </v-radio-group>
                        <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import Instructions from "../../../common/Instructions.vue";
import PolicyDetails from "./PolicyDetails.vue";
import BuildingDetails from "./BuildingDetails.vue";

export default {
    name: "NewPolicyPage",

    components: {
        Instructions,
        PolicyDetails,
        BuildingDetails,
    },
    data() {
        return {
            isSaving: false,
            selectedOption: "Take-Up",
        };
    },

    props: {
        appData: Object,
        refData: Object,
        isReadOnly: Boolean,
    },

    created() {
        // Ensure declarationRequired has an initial value if not set
        if (!this.appData.declarationRequired) {
            this.$set(this.appData, 'declarationRequired', null);
        }
    },

    methods: {
        transactionTypeChanged: function () {
            if (this.appData.transaction.K_Type === "Take-Up") {
                this.appData.insurer = null;
                this.appData.policy.K_PolicyNumber = null;
            }
            this.$emit("updateWizardTitle");
        },

        handleDeclarationChange(value) {
            // Ensure the value is set in appData
            this.$set(this.appData, 'declarationRequired', value);

            // Call the existing method
            this.setPolicyEnteredStatus();

            // Save changes automatically when selection changes
            this.saveChanges();
        },

        setPolicyEnteredStatus: function () {
            // Create a copy of existing policy data
            const currentPolicy = { ...this.appData.policy };

            this.appData.statusFlags.policyDetailsEntered = true;

            // Ensure policy data is preserved
            this.$nextTick(() => {
                this.appData.policy = currentPolicy;
            });

            this.$emit("updateServerTransactionStatus", "policyDetailsEntered");
        },

        async handleStrataCompanyChange(value) {
            if (value) {
                // Ensure proper saving after strata company change
                await this.saveChanges();
            }
        },

        async saveChanges() {
            try {
                const isValid = await this.$refs.wizardPage1.validate();
                if (!isValid) return;

                this.isSaving = true;

                // Store current policy data
                const currentPolicy = { ...this.appData.policy };

                // Emit save-changes event
                this.$emit('save-changes');

                // Ensure policy data is preserved after save
                this.$nextTick(() => {
                    this.appData.policy = currentPolicy;
                });

            } catch (error) {
                // Show error message
                this.$bvToast.toast('Failed to save changes. Please try again.', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                });
                console.error('Save failed:', error);
            } finally {
                this.isSaving = false;
            }
        }
    },

    computed: {
        instructions: function () {
            return "Please enter the general details relating to the policy and building below.";
        },
    },
};
</script>
