<template>
    <div>
        <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
            <h4 class="font-weight-bold text-dark" v-b-toggle="'policy-details-panel'">
                <i class="fas fa-list-alt fa-md text-primary"></i>
                <span class="ml-2" style="vertical-align: center">Policy Details</span>

                <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
                <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
            </h4>

            <b-collapse id="policy-details-panel" v-model="isExpanded">
                <div v-if="appData && refData">

                    <!-- ROW 1 -->
                    <div class="form-group row mb-3">
                        <label class="col-2 col-form-label text-right">Policy Name</label>
                        <div class="col-4">
                            <ValidationProvider rules="required|max:255" name="Name" v-slot="{ errors }">
                                <input class="form-control" maxlength="255" v-model="appData.policy.Name"
                                    :disabled="isReadOnly" @blur="$emit('updateWizardTitle')" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <label class="col-2 col-form-label text-right">Policy Owner<span
                                class="text-danger">*</span></label>

                        <div class="col-4">
                            <ValidationProvider rules="required" name="Owner" v-slot="{ errors }">
                                <v-autocomplete v-model="appData.policy.Owner" :items="refData.activeUsers"
                                    item-text="full_name" item-value="id" :disabled="isReadOnly" dense clearable
                                    :label="appData.policy.Owner ? '' : 'Select Owner'" hint="Start typing to search users"
                                    return-object class="custom-autocomplete" :menu-props="{ offsetY: true }" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <!-- ROW 2 -->
                    <div class="form-group row mb-3">
                        <label class="col-2 col-form-label text-right">Strata Company<span
                                class="text-danger">*</span></label>
                        <div class="col-4">

                            <ValidationProvider rules="required" name="K_StrataCompany" v-slot="{ errors }">
                                <v-autocomplete v-model="appData.transaction.K_StrataCompany"
                                    :items="availableStrataCompanies" item-text="Name" item-value="id"
                                    :disabled="isReadOnly" dense clearable
                                    :label="appData.transaction.K_StrataCompany ? '' : 'Select Strata Company'"
                                    @change="handleStrataCompanySelection" hint="Start typing to search strata companies"
                                    return-object class="custom-autocomplete" @input="$emit('strataCompanyChanged', $event)"
                                    :menu-props="{ offsetY: true }" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <label v-if="appData.transaction.K_StrataCompany" class="col-2 col-form-label text-right">Primary
                            Policy Contact<span class="text-danger">*</span>
                        </label>
                        <div class="col-4" v-if="appData.transaction.K_StrataCompany">
                            <ValidationProvider rules="required" name="K_PrimaryPolicyContact" v-slot="{ errors }">
                                <v-autocomplete v-model="selectedContact" :items="availableContacts" item-text="Name"
                                    item-value="id" :disabled="isReadOnly || !appData.transaction.K_StrataCompany"
                                    :loading="loadingContacts" dense clearable
                                    :label="selectedContact ? '' : 'Select Contact'" hint="Start typing to search contacts"
                                    return-object class="custom-autocomplete" :menu-props="{ offsetY: true }"
                                    :no-data-text="loadingContacts ? 'Loading contacts...' : 'No contacts found'">
                                    <template v-slot:selection="{ item }">
                                        <div class="custom-chip mb-1">
                                            {{ item.Name }}
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.Name }}</v-list-item-title>
                                            <v-list-item-subtitle v-if="item.Email">
                                                <i class="fa-solid fa-envelope fa-sm mr-1"></i>{{ item.Email }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="item.K_Phone">
                                                <i class="fa-solid fa-phone fa-sm mr-1"></i>{{ item.K_Phone }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <!-- ROW 3 -->
                    <div class="form-group row mb-3">
                        <label class="col-2 col-form-label text-right">Policy From Date<span
                                class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="required" name="K_FromDate" v-slot="{ errors }">
                                <b-form-datepicker class="form-control" :locale="'en-AU'"
                                    v-model="appData.transaction.K_FromDate" :disabled="isReadOnly" :date-format-options="{
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    }" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <label class="col-2 col-form-label text-right">Policy To Date<span
                                class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="required" name="K_ToDate" v-slot="{ errors }">
                                <b-form-datepicker class="form-control" :locale="'en-AU'"
                                    v-model="appData.transaction.K_ToDate" :disabled="isReadOnly" :date-format-options="{
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    }" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <!-- ROW 4 -->
                    <div class="form-group row mb-3" v-if="appData.transaction.K_Type === 'Take-Up'">
                        <label class="col-2 col-form-label text-right">Insurer<span class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="required" name="K_Insurer" v-slot="{ errors }">
                                <v-autocomplete v-model="appData.policy.K_Insurer" :items="availableInsurers"
                                    item-text="Name" item-value="id" :disabled="isReadOnly" dense clearable
                                    :label="appData.policy.K_Insurer ? '' : 'Select Insurer'"
                                    hint="Start typing to search insurers" return-object class="custom-autocomplete"
                                    :menu-props="{ offsetY: true }" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <label class="col-2 col-form-label text-right">CIB Appointed Date<span
                                class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="required" name="K_AppointedDate" v-slot="{ errors }">
                                <b-form-datepicker class="form-control" :locale="'en-AU'"
                                    v-model="appData.policy.K_AppointedDate" :disabled="isReadOnly" :date-format-options="{
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    }" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <!-- ROW 5 -->
                    <div class="form-group row mb-3">
                        <label class="col-2 col-form-label text-right">Product<span class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider rules="required" name="K_Product" v-slot="{ errors }">
                                <v-autocomplete v-model="appData.policy.K_Product" :items="availableProducts"
                                    item-text="Name" item-value="id" :disabled="isReadOnly" dense clearable
                                    :label="appData.policy.K_Product ? '' : 'Select Product'"
                                    hint="Start typing to search products" return-object class="custom-autocomplete"
                                    :menu-props="{ offsetY: true }" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <label v-if="appData.transaction.K_Type === 'Take-Up'"
                            class="col-2 col-form-label text-right">Policy Number<span class="text-danger">*</span></label>
                        <div class="col-4">
                            <ValidationProvider v-if="appData.transaction.K_Type === 'Take-Up'" rules="required|max:255"
                                name="K_PolicyNumber" v-slot="{ errors }">
                                <input class="form-control" maxlength="255" v-model="appData.policy.K_PolicyNumber"
                                    :disabled="isReadOnly" />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                </div>
            </b-collapse>
        </b-card>
    </div>
</template>

<style>
.form-control:disabled,
.form-control[readonly] {
    color: #3f4254;
    cursor: not-allowed;
    background-color: #f3f6f9;
}

.custom-autocomplete {
    font-size: 13px !important;
}

.custom-autocomplete .v-select__selection {
    font-size: 14px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
}

.b-form-btn-label-control.form-control[aria-disabled="true"],
.b-form-btn-label-control.form-control[aria-readonly="true"] {
    background-color: #f3f6f9;
    cursor: not-allowed;
}
</style>

<script>
import _ from "lodash";
import moment from "moment";

export default {
    name: "PolicyDetails",

    components: {},

    props: {
        appData: Object,
        refData: Object,
        isReadOnly: Boolean,
    },

    data() {
        return {
            isExpanded: true,
            strataCompanyContacts: [],
            loadingContacts: false
        };
    },

    mounted() { },

    methods: {
        async loadContacts(strataCompanyId) {
            this.loadingContacts = true;
            try {
                const response = await this.$newBusinessDataService.getStrataCompanyContacts(strataCompanyId);
                if (response?.data?.result) {
                    // Clean the contacts data before storing
                    this.strataCompanyContacts = response.data.result.map(contact => ({
                        id: contact.id,
                        Name: contact.Name,
                        Email: contact.Email,
                        K_Phone: contact.K_Phone
                    })).sort((a, b) => (a.Name || '').localeCompare(b.Name || ''));
                }
            } catch (error) {
                console.error('Error fetching contacts:', error);
                this.$emit('handleError', error);
            } finally {
                this.loadingContacts = false;
            }
        },

        async handleStrataCompanySelection(strataCompany) {
            // Clear the primary contact when strata company changes
            this.appData.policy.K_PrimaryPolicyContact = null;
            this.strataCompanyContacts = [];

            if (!strataCompany) return;

            // Load new contacts
            await this.loadContacts(strataCompany.id);
        },
    },

    computed: {
        selectedContact: {
            get() {
                return this.appData.policy.K_PrimaryPolicyContact;
            },
            set(value) {
                // Create a clean copy of just the fields we need
                const contactData = value ? {
                    id: value.id,
                    Name: value.Name,
                    Email: value.Email,
                    K_Phone: value.K_Phone
                } : null;

                this.$set(this.appData.policy, 'K_PrimaryPolicyContact', contactData);
            }
        },

        availableStrataCompanies() {
            if (!this.refData?.allStrataCompanies) return [];

            // Clean copy of just the fields we need
            return this.refData.allStrataCompanies.map(sc => ({
                id: sc.id,
                Name: sc.Name
            }));
        },

        availableInsurers: function () {
            let insurers = [];
            if (this.refData?.allInsurers) {
                insurers = this.refData.allInsurers.map(insurer => ({
                    id: insurer.id,
                    Name: insurer.Name,
                    K_Panel: insurer.K_Panel
                }));
            }
            // If we have a selected insurer but it's not in the list, add it
            if (this.appData?.policy?.K_Insurer?.id &&
                !insurers.find(i => i.id === this.appData.policy.K_Insurer.id)) {
                insurers.push(this.appData.policy.K_Insurer);
            }
            return insurers;
        },

        availableProducts: function () {
            let products = [];
            if (this.refData?.allProducts) {
                products = this.refData.allProducts.map(product => ({
                    id: product.id,
                    Name: product.Name
                }));
            }
            // If we have a selected product but it's not in the list, add it
            if (this.appData?.policy?.K_Product?.id &&
                !products.find(p => p.id === this.appData.policy.K_Product.id)) {
                products.push(this.appData.policy.K_Product);
            }
            return products;
        },

        availableContacts() {
            // Only show contacts for current strata company
            return this.strataCompanyContacts;
        },

    },



    watch: {
        'appData.transaction.K_FromDate': function (newVal) {
            if (newVal && !this.appData.transaction.K_ToDate) {
                this.appData.transaction.K_ToDate = moment(newVal).add(12, 'months').format('YYYY-MM-DD');
            }
        },
        'appData.policy.K_PolicyNumber': function (newVal) {
            // Default name will be "Draft Policy" if no policy number
            this.appData.policy.Name = newVal ? newVal : 'Draft Policy';
            // Emit event to update wizard title in parent
            this.$emit('updateWizardTitle');
        },

        'appData.transaction.K_FromDate': function (newVal) {
            if (newVal && !this.appData.transaction.K_ToDate) {
                this.appData.transaction.K_ToDate = moment(newVal).add(12, 'months').format('YYYY-MM-DD');
            }
        },

        'appData.transaction.K_StrataCompany': {
            immediate: true,
            handler(newVal) {
                if (newVal?.id) {
                    this.loadContacts(newVal.id);
                }
            }
        },

        'appData': {
            immediate: true,
            deep: true,
            handler(newVal) { }
        }
    }
};
</script>
